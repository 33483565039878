import { BasicLayout } from "../layouts";

export default function Blog() {
  document.title = "DEV3CH | Blogs";
  return (
    <>
      <BasicLayout>
        <main>
          <h1>Blogs :)</h1>
        </main>
      </BasicLayout>
    </>
  );
}
